import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  CardActions,
  CardMedia,
  useMediaQuery,
  ThemeProvider,
  Container,
  TextField,
} from "@mui/material";
import DashBoardImg from "../../assets/images/dashboard-1.jpg";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTheme } from "@emotion/react";
import Performances from "./Performances/Performances";
import Deals from "./Deals/Deals";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LineChart, PieChart } from "@mui/x-charts";
import { getTheme } from "../../styles/theme";
import StyledTitleDot from "../common/StyledTitleDot";
import BreadCrumbs from "../Breadcrumbs";
import Residences from "./Deals/Map";
import { useDispatch, useSelector } from "react-redux";
import PaginationTable from "../FilterPagesGeneral/PaginationTable";
import {
  TableHeaders,
  TableHeadersMd,
  TableHeadersSm,
} from "../TableHeaders/TableHeaders";
import { fetchListItems } from "../../redux/actions/actions";
import DashboardTable from "./DashboardTable";
import BestDeals from "./BestDeals/BestDeals";
import { LineChartComp } from "./Charts/LineChart/LineChartComp";
import { PieChartComp } from "./Charts/PieChart/PieChartComp";
import PerformanceCards from "./Performances/PerformanceCards";
import { BarChartComp } from "./Charts/BarChart/BarChart";
import ApexChart from "./Charts/ApexChart";
import ApexChart2 from "./Charts/ApexChart2";
import DateRangeContext, {
  DateRangeProvider,
} from "../../contexts/Dashboard/DateRange";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SliderArea from "./SliderArea/SliderArea";
import TopBookedCountries from "./TopBookedCountries/TopBookedCountries";
import TotalCommissions from "./Charts/TotalCommissions/TotalCommissions";
import RecentActivities from "./RecentActivities/RecentActivities";
import ProviderWrapperArea from "./Deals/Map/ProviderWrapperArea";
// import ProgressBar from "./ProgressBar/ProgressBar";

export default function DashboardMain({
  open,
  setOpen,
  isMobile,
  setIsMobile,
}) {
  const headerStyle = {
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "space-between",
    padding: "1rem 2rem",
    alignItems: "center",
    height: "64px",
  };

  const cardsInit = [
    {
      name: "Total Sales",
      id: "total_sales",
      value: "",
      icon: CurrencyPoundIcon,
    },
    {
      name: "Total Invoices",
      id: "total_invoices",
      value: "",
      icon: ReceiptIcon,
    },
    // {
    //   name: "Total Commissions",
    //   id: "total_commissions",
    //   value: "",
    //   icon: CurrencyPoundIcon,
    // },
    {
      name: "Booked Students",
      id: "total_students",
      value: "",
      icon: PersonIcon,
    },
  ];

  const theme = useTheme();
  const isMobileSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileMd = useMediaQuery(theme.breakpoints.down("md"));
  const selectedBranchesString = useSelector(
    (state) => state?.branches?.branchesString
  );
  const branchesArray = useSelector((state) => state?.branches?.branches);

  const [tableHeaderBookings, setTableHeaderBookings] = useState([]);
  const [tableHeaderInvoices, setTableHeaderInvoices] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(true);

  const profile = useSelector((state) => state.profile.profile);

  const [cards, setCards] = useState(cardsInit);
  const [allTableHeaderBookings, setAllTableHeaderBookings] = useState(null);
  const [allTableHeaderInvoices, setAllTableHeaderInvoices] = useState(null);
  const [tableCardRefControl, setTableCardRefControl] = useState({
    bool: false,
    type: null,
  });

  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateRangeContext);

  const tableCardRef = useRef();

  // Bookings için useEffect
  useEffect(() => {
    const tableHeaderDataBookings = TableHeaders.find(
      (header) => "bookings" in header
    )["bookings"];
    let newTableHeaderBookings = tableHeaderDataBookings;
    setAllTableHeaderBookings(newTableHeaderBookings);
    if (isMobileSm) {
      newTableHeaderBookings = TableHeadersSm.find(
        (header) => "bookings" in header
      )["bookings"];
    } else if (isMobileMd) {
      newTableHeaderBookings = TableHeadersMd.find(
        (header) => "bookings" in header
      )["bookings"];
    } else if (tableCardRefControl.bool) {
      if (tableCardRefControl.type === "md") {
        newTableHeaderBookings = TableHeadersMd.find(
          (header) => "bookings" in header
        )["bookings"];
      } else if (tableCardRefControl.type === "sm") {
        newTableHeaderBookings = TableHeadersSm.find(
          (header) => "bookings" in header
        )["bookings"];
      }
    }

    setTableHeaderBookings(newTableHeaderBookings);
  }, [isMobileSm, isMobileMd, tableCardRefControl]);

  // Invoices için useEffect
  useEffect(() => {
    const tableHeaderDataInvoices = TableHeaders.find(
      (header) => "commissions" in header
    )["commissions"];
    let newTableHeaderInvoices = tableHeaderDataInvoices;
    setAllTableHeaderInvoices(newTableHeaderInvoices);
    if (isMobileSm) {
      newTableHeaderInvoices = TableHeadersSm.find(
        (header) => "commissions" in header
      )["commissions"];
    } else if (isMobileMd) {
      newTableHeaderInvoices = TableHeadersMd.find(
        (header) => "commissions" in header
      )["commissions"];
    }

    setTableHeaderInvoices(newTableHeaderInvoices);
  }, [isMobileSm, isMobileMd]);

  useEffect(() => {
    if (
      tableCardRef?.current?.offsetWidth < 1000 &&
      tableCardRef?.current?.offsetWidth >= 500
    ) {
      setTableCardRefControl({ bool: true, type: "md" });
    } else if (tableCardRef?.current?.offsetWidth < 500) {
      setTableCardRefControl({ bool: true, type: "sm" });
    }
    const handleResize = () => {
      if (
        tableCardRef?.current?.offsetWidth < 1000 &&
        tableCardRef?.current?.offsetWidth >= 500
      ) {
        setTableCardRefControl({ bool: true, type: "md" });
      } else if (tableCardRef?.current?.offsetWidth < 500) {
        setTableCardRefControl({ bool: true, type: "sm" });
      } else {
        setTableCardRefControl(false, null);
      }
    };
    window.addEventListener("resize", handleResize);
  }, []);
  // useEffect(() => {
  //   const tableHeaderData = TableHeaders.find((header) => "bookings" in header)[
  //     "bookings"
  //   ];
  //   setAllTableHeader(tableHeaderData);
  //   if ("bookings" !== "invoices") {
  //     const tableHeaderSmData = TableHeadersSm.find(
  //       (header) => "bookings" in header
  //     )["bookings"];

  //     const tableHeaderMdData = TableHeadersMd.find(
  //       (header) => "bookings" in header
  //     )["bookings"];

  //     if (tableHeaderData) {
  //       let newTableHeader = [...tableHeaderData]; // tableHeader'ı kopyalayarak başlayın

  //       if (isMobileSm) {
  //         newTableHeader = tableHeaderSmData;
  //       } else if (isMobileMd) {
  //         newTableHeader = tableHeaderMdData;
  //       }

  //       setTableHeader(newTableHeader);
  //     } else {
  //     }
  //   }
  // }, [isMobileSm, isMobileMd]);

  // useEffect(() => {
  //   if (profile?.account_type === "main_branch") {
  //     let newCards = cardsInit.filter((item) => {
  //       return item.id !== "total_commissions";
  //     });
  //     setCards(newCards);
  //   } else {
  //     setCards(cardsInit);
  //   }
  // }, [profile]);
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Typography className="title" variant="h4">
          Dashboard
          <StyledTitleDot />
        </Typography>
        <BreadCrumbs routs={["Dashboard"]} component={"dashboard"} />

        <Box sx={{ marginTop: "3rem" }}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6} marginLeft={"auto"}>
              <ProgressBar />
            </Grid> */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label="Start Date"
                      value={startDate}
                      onChange={setStartDate}
                      renderInput={(params) => <TextField {...params} />}
                      maxDate={endDate}
                    />
                  </LocalizationProvider>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    marginLeft: { xs: "0", md: "1rem" },
                    marginTop: { xs: "1rem", md: "0" },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label="End Date"
                      value={endDate}
                      onChange={setEndDate}
                      renderInput={(params) => <TextField {...params} />}
                      minDate={startDate}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Grid>

            <PerformanceCards
              loadingProfile={loadingProfile}
              setLoadingProfile={setLoadingProfile}
              cards={cards}
              setCards={setCards}
            />
          </Grid>

          <Grid container spacing={3} sx={{ marginTop: "2rem" }}>
            <Grid item xs={12} lg={6} xl={5}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1.5rem 1rem",
                }}
              >
                <SliderArea />
                {/* <CardMedia
                  component="iframe"
                  height="100%" // İhtiyacınıza göre ayarlayın
                  image="https://www.youtube.com/embed/t_jmqnFsKkw?si=IOpd7I-jqlscYcTT" // YOUR_VIDEO_ID yerine YouTube video ID'nizi girin
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title="Embedded YouTube Video"
                /> */}
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} xl={7}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1.5rem 1rem",
                }}
              >
                {/* <PieChart
                  colors={[
                    "#bf0000",
                    getTheme().palette.customRed.dark,
                    getTheme().palette.customRed.extraDark,
                  ]}
                  series={[
                    {
                      data: [
                        { id: 0, value: 10, label: "series A" },
                        { id: 1, value: 15, label: "series B" },
                        { id: 2, value: 20, label: "series C" },
                      ],
                    },
                  ]}
                  width={400}
                  height={200}
                /> */}
                {/* <PieChartComp /> */}
                <ApexChart2 />
              </Card>
            </Grid>

            {/* {profile?.agency_type !== "Institution" &&
            profile?.account_type === "main_branch" ? (
              <Grid item xs={12} lg={12} xl={12}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1.5rem 1rem",
                  }}
                >
                  <TotalCommissions />
                </Card>
              </Grid>
            ) : null} */}

            <Grid item xs={12}>
              <TopBookedCountries open={open} />
            </Grid>

            <Grid item xs={12} md={12}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1.5rem 1rem",
                }}
              >
                {/* <LineChart
                  xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                  series={[
                    {
                      data: [2, 5.5, 2, 8.5, 1.5, 5],
                      color: "#bf0000",
                    },
                  ]}
                  width={500}
                  height={300}
                /> */}
                {/* <LineChartComp /> */}
                {/* <BarChartComp /> */}
                <ApexChart />
              </Card>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1.5rem 1rem",
                }}
              >
                //  <PieChart
                //   colors={[
                //     "#bf0000",
                //     getTheme().palette.customRed.dark,
                //     getTheme().palette.customRed.extraDark,
                //   ]}
                //   series={[
                //     {
                //       data: [
                //         { id: 0, value: 10, label: "series A" },
                //         { id: 1, value: 15, label: "series B" },
                //         { id: 2, value: 20, label: "series C" },
                //       ],
                //     },
                //   ]}
                //   width={400}
                //   height={200}
                // />
                <PieChartComp />
              </Card>
            </Grid> */}
            <Grid item xs={12} lg={12}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <ProviderWrapperArea
                  open={open}
                  setOpen={setOpen}
                  isMobile={isMobile}
                  setIsMobile={setIsMobile}
                />
                {/* <MyMap /> */}
              </Card>
            </Grid>
            {/* COMMISSION CHANGED */}
            <Grid item xs={12} lg={12}>
              <Card
                ref={tableCardRef}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1rem",
                }}
              >
                <DashboardTable
                  tableHeader={tableHeaderBookings}
                  allTableHeader={allTableHeaderBookings}
                  isMobileSm={isMobileSm}
                  isMobileMd={isMobileMd}
                  tableType={"bookings"}
                  tableCardRefControl={tableCardRefControl}
                />
              </Card>
            </Grid>

            {/* {profile?.account_type === "main_branch" &&
            profile?.agency_type !== "Institution" ? (
              <Grid item xs={12}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1rem",
                  }}
                >
                  <DashboardTable
                    tableHeader={tableHeaderInvoices}
                    allTableHeader={allTableHeaderInvoices}
                    isMobileSm={isMobileSm}
                    isMobileMd={isMobileMd}
                    tableType={"commissions"}
                  />
                </Card>
              </Grid>
            ) : null} */}

            {/* {profile?.account_type === "main_branch" ? (
              <Grid item xs={12}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1rem",
                  }}
                >
                  <DashboardTable
                    tableHeader={tableHeaderInvoices}
                    allTableHeader={allTableHeaderInvoices}
                    isMobileSm={isMobileSm}
                    isMobileMd={isMobileMd}
                    tableType={"commissions"}
                  />
                </Card>
              </Grid>
            ) : null} */}

            <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: 'flex',
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1rem 1rem 3rem 1rem",
                }}
              >
                <RecentActivities />
              </Card>
            </Grid>
            {/* <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1rem 1rem 3rem 1rem",
                }}
              >
                <RecentActivities />
              </Card>
            </Grid> */}

            <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1rem 1rem 3rem 1rem",
                }}
              >
                <BestDeals />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
