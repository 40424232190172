import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Box, Card, CardMedia } from "@mui/material";

import KayakDesktopSm from "../../../assets/images/slider/dashboard/kayak_mobile.png";
import KayakDesktop from "../../../assets/images/slider/dashboard/kayak-mobile.png";
import FiftyMobile from "../../../assets/images/slider/dashboard/fifty_mobile.jpg";
import FiftyDesktop from "../../../assets/images/slider/dashboard/fifty_desktop.jpg";

import NoDepositDesktop from "../../../assets/images/slider/dashboard/nodeposit_desktop.jpg";
import NoDepositMobile from "../../../assets/images/slider/dashboard/nodeposit_mobile.png";

import StartSmartDesktop from "../../../assets/images/slider/dashboard/start-smart-desktop.png";
import StartSmartMobile from "../../../assets/images/slider/dashboard/start-smart-mobile.png";

import JetStayDesktop from "../../../assets/images/slider/dashboard/jet-stay-desktop.png";
import JetStayMobile from "../../../assets/images/slider/dashboard/jet-stay-mobile.png";

import FlexibleStaysDesktop from "../../../assets/images/slider/dashboard/flexible-stays-desktop.png";
import FlexibleStaysMobile from "../../../assets/images/slider/dashboard/flexible-stays-mobile.png";

import FiveStartFiveYearMobile from "../../../assets/images/slider/dashboard/five-star_five-year_mobile.png";
import FiveStartFiveYearDesktop from "../../../assets/images/slider/dashboard/five-star_five-year_desktop.png";

import ShareTheRoomMobile from "../../../assets/images/slider/dashboard/share-the-room-mobile.png";
import ShareTheRoomDesktop from "../../../assets/images/slider/dashboard/share-the-room-desktop.png";

import XmaxGiftMobile from "../../../assets/images/slider/dashboard/xmas_gift_mobile.jpg";
import XmaxGiftDesktop from "../../../assets/images/slider/dashboard/xmas_gift_desktop.jpg";

import BlackFridayMobile from "../../../assets/images/slider/dashboard/black_friday_mobile.png";
import BlackFridayDesktop from "../../../assets/images/slider/dashboard/black_friday_desktop.png";

import SoundonMobile from "../../../assets/images/slider/dashboard/soundon_mobile.png";
import SoundonDesktop from "../../../assets/images/slider/dashboard/soundon_desktop.png";

import AnyInternsMobile from "../../../assets/images/slider/dashboard/any_interns_mobile.png";
import AnyInternsDesktop from "../../../assets/images/slider/dashboard/any_interns_desktop.png";

import RiseShineMobile from "../../../assets/images/slider/dashboard/rise-shine-mobile.jpg";
import RiseShineDesktop from "../../../assets/images/slider/dashboard/rise-shine-desktop.jpg";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function App() {
  const lightMode = useSelector((state) => state.mode.lightMode); // Redux state'inden lightMode değerini çekin

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Swiper
        navigation={true}
        // pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Link
            target="_blank"
            style={{ width: "100%", height: "100%" }}
            to="https://londonist.co.uk/sound-on-stress-off?utm_source=mailjet&utm_medium=referrer&utm_campaign=paddington-residence&utm_term=paddington-residence-rebooker&utm_content=paddington-rebooker"
          >
            <img
              src={window?.innerWidth < 768 ? SoundonMobile : SoundonDesktop}
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Link
            target="_blank"
            style={{ width: "100%", height: "100%" }}
            to="https://londonist.co.uk/any-interns"
          >
            <img
              src={
                window?.innerWidth < 768 ? AnyInternsMobile : AnyInternsDesktop
              }
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Link>
        </SwiperSlide>

        <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Link
            target="_blank"
            style={{ width: "100%", height: "100%" }}
            to="https://londonist.co.uk/blogs/rise-and-shine-londonist-family"
          >
            <img
              src={
                window?.innerWidth < 768 ? RiseShineMobile : RiseShineDesktop
              }
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Link>
        </SwiperSlide>

        {/* <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Link
            target="_blank"
            style={{ width: "100%", height: "100%" }}
            to="https://londonist.co.uk/black-friday-deal"
          >
            <img
              src={
                window?.innerWidth < 768
                  ? BlackFridayMobile
                  : BlackFridayDesktop
              }
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Link>
        </SwiperSlide> */}

        {/* <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Link
            target="_blank"
            style={{ width: "100%", height: "100%" }}
            to="https://londonist.co.uk/landing-student/student-accommodation-christmas-gift?utm_source=mailjet&utm_medium=email&utm_campaign=autumn-special-2024&utm_term=xmas-gift&utm_content=xmas-gift-2024"
          >
            <img
              src={window?.innerWidth < 768 ? XmaxGiftMobile : XmaxGiftDesktop}
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Link>
        </SwiperSlide> */}

        {/* <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <img
              src={
                window?.innerWidth < 768
                  ? ShareTheRoomMobile
                  : ShareTheRoomDesktop
              }
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Box>
        </SwiperSlide> */}

        {/* <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <img
              src={
                window?.innerWidth < 768
                  ? FiveStartFiveYearMobile
                  : FiveStartFiveYearDesktop
              }
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Box>
        </SwiperSlide> */}

        <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <img
              src={
                window?.innerWidth < 768
                  ? FlexibleStaysMobile
                  : FlexibleStaysDesktop
              }
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Box>
        </SwiperSlide>

        {/* <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <img
              src={window?.innerWidth < 768 ? JetStayMobile : JetStayDesktop}
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Box>
        </SwiperSlide> */}

        {/* <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <img
              src={
                window?.innerWidth < 768 ? StartSmartMobile : StartSmartDesktop
              }
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Box>
        </SwiperSlide> */}

        {/* <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <img
              src={
                window?.innerWidth < 768 ? NoDepositMobile : NoDepositDesktop
              }
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Box>
        </SwiperSlide> */}
        {/* <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <img
              src={window?.innerWidth < 768 ? FiftyMobile : FiftyDesktop}
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Box>
        </SwiperSlide> */}
        <SwiperSlide
          style={{
            userSelect: "none",
            background: lightMode ? "#ffffff" : "#343434",
          }}
        >
          <Link
            target="_blank"
            style={{ width: "100%", height: "100%" }}
            to="https://www.kayak.co.uk/?utm_source=tradetracker&utm_medium=affiliate&utm_campaign=472424&utm_content=32925"
          >
            {/* <Box
              sx={{
                backgroundImage: `url(${KayakDesktop})`,
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundPositionX: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: ".5rem",
              }}
            ></Box> */}
            <img
              src={window?.innerWidth < 768 ? KayakDesktopSm : KayakDesktop}
              style={{ borderRadius: ".5rem" }}
              alt=""
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ background: lightMode ? "#ffffff" : "#343434" }}>
          <Card
            sx={{
              height: { xs: "100%", lg: "100%" },
              width: "100%",
              borderRadius: ".5rem",
            }}
          >
            <CardMedia
              component="iframe"
              style={{ borderRadius: ".5rem" }}
              height="100%" // İhtiyacınıza göre ayarlayın
              image="https://www.youtube.com/embed/t_jmqnFsKkw?si=IOpd7I-jqlscYcTT" // YOUR_VIDEO_ID yerine YouTube video ID'nizi girin
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              title="Embedded YouTube Video"
            />
            {/* <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Video Title
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Video description can be found here{" "}
                  </Typography>
                </CardContent> */}
          </Card>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
}
